<template>
  <b-modal
    :id="id"
    size="sm"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreate($event)"
    @hidden="resetData"
  >
    <div class="modal-report">
      <validation-observer ref="rule">
        <b-form class="content-form">

          <b-form-group>
            <label for="basicInput">Hạng<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="equip"
            >
              <v-select
                v-model="dataInput.licenseTrainingType"
                :reduce="item => item .en"
                label="vi"
                :options="dataCombobox"
                :placeholder="'Lựa chọn hạng'"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import VSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    VSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataCombobox: {
      type: Array,
      default: _ => [],
    },
  },
  data() {
    return {
      required,
      equip: {
        required: 'Chọn loại thiết bị là bắt buộc',
      },
      count: {
        required: 'Số lượng là bắt buộc',
      },
      dataInput: {
        licenseTrainingType: '',
      },
    }
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {}
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
