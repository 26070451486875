<template>
  <div id="licenseId">
    <my-tabs
      :listTabs="listTabs"
      defaultActive="actions"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div>
      <actions
        v-if="isRendered('actions')"
        :fetchDataTraining="fetchDataTraining"
        :dataInfomationTraining="dataInfomationTraining"
        class="tab-pane page-container"
        :class="{'active':activeTab==='actions'}"
        @handleDeleteLicense="handleDeleteLicense"
      />
      <general-infomation
        v-if="isRendered('general-infomation')"
        :licenseTrainingId="licenseTrainingId"
        class="tab-pane page-container"
        :class="{'active':activeTab==='general-infomation'}"
      />
      <file-send
        v-if="isRendered('file-requires')"
        :licenseTrainingId="licenseTrainingId"
        :typeFile="typeFile"
        class="tab-pane page-container"
        :class="{'active':activeTab==='file-requires'}"
      />
      <test-object
        v-if="isRendered('training-field')"
        :fetchDataTraining="fetchDataTraining"
        :licenseTrainingId="licenseTrainingId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='training-field'}"
      />
      <add-file
        v-if="isRendered('files')"
        :licenseTrainingId="licenseTrainingId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='files'}"
      />
      <equipment
        v-if="isRendered('training-equipment')"
        :licenseTrainingId="licenseTrainingId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='training-equipment'}"
      />
      <document
        v-if="isRendered('training-manager-offical')"
        :licenseTrainingId="licenseTrainingId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='training-manager-offical'}"
      />
      <inspector
        v-if="isRendered('trainer')"
        :licenseTrainingId="licenseTrainingId"
        class="tab-pane page-container-table"
        :class="{'active':activeTab==='trainer'}"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import MyTabs from '@/components/tabs/MyTabs.vue'
import Actions from './components/Actions.vue'
import TestObject from './components/TestObject.vue'
import AddFile from './components/AddFile.vue'
import Equipment from './components/Equipment.vue'
import Document from './components/Document.vue'
import Inspector from './components/Inspector.vue'
import FileSend from './components/FileSends.vue'
import GeneralInfomation from './components/GeneralInfomation.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    MyTabs,
    Actions,
    TestObject,
    AddFile,
    Equipment,
    Document,
    Inspector,
    GeneralInfomation,
    FileSend,
  },
  data() {
    return {
      activeTab: null,
      listTabs: [
        {
          key: 'actions',
          title: 'Hành động',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'file-requires',
          title: 'Hồ sơ cần nộp',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'general-infomation',
          title: 'Thông tin chung của bản thuyết minh',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'training-field',
          title: 'Lĩnh vực, công việc hoạt động huấn luyện',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'files',
          title: 'File đính kèm',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'training-equipment',
          title: 'Thiết bị huấn luyện sử dụng chung',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'training-manager-offical',
          title: 'Cán bộ quản lý',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'trainer',
          title: 'Người huấn luyện cơ hữu',
          icon: '',
          isDisabled: false,
          isRendered: false,
        },
      ],
      dataInfomationTraining: {},
      licenseTrainingId: null,
      typeFile: null,
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
  },
  async created() {
    await this.fetchDataTraining()

    if (this.activeTab === null) {
      this.activeTab = 'actions'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    // Lấy thông tin cấp phép kiểm định theo doanh nghiệp
    async fetchDataTraining() {
      const data = await axiosApiInstance.get(ConstantsApi.INFORMATIN_TRAINING_BUSINESS)
      if (data?.data?.statusLicenseTraining !== 'NonLiscening') {
        this.licenseTrainingId = data?.data?.id
        this.typeFile = data?.data?.statusLicenseTraining
        this.dataInfomationTraining = data?.data
        this.listTabs[1].isDisabled = false
        this.listTabs[2].isDisabled = false
        this.listTabs[3].isDisabled = false
        this.listTabs[4].isDisabled = false
        this.listTabs[5].isDisabled = false
        this.listTabs[6].isDisabled = false
      }
      // eslint-disable-next-line no-constant-condition
      if ((['Licensing', 'Renew', 'Extend', 'RejectLicense', 'Revoke'].includes(data?.data?.statusLicenseTraining))
            && data.status !== 204) {
        this.licenseTrainingId = data?.data?.id
        this.typeFile = data?.data?.statusLicenseTraining
        this.dataInfomationTraining = data?.data
        this.listTabs[1].isDisabled = true
        this.listTabs[2].isDisabled = true
        this.listTabs[3].isDisabled = true
        this.listTabs[4].isDisabled = true
        this.listTabs[5].isDisabled = true
        this.listTabs[6].isDisabled = true
      }
      if (data.status === 204) {
        this.listTabs[1].isDisabled = true
        this.listTabs[2].isDisabled = true
        this.listTabs[3].isDisabled = true
        this.listTabs[4].isDisabled = true
        this.listTabs[5].isDisabled = true
        this.listTabs[6].isDisabled = true
        this.dataInfomationTraining = {
          statusLicenseTraining: 'NonLiscening',
          statusLicenseTrainingString: 'Chưa cấp phép',
        }
      }
    },
    async handleDeleteLicense() {
      const deleteId = []
      deleteId.push(this.licenseTrainingId)
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.DELETE_LICENSE_TRAINING, deleteId)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa giấy phép thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataTraining()
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa giấy phép không thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
  },
}
</script>
