<template>
  <b-modal
    :id="id"
    size="md"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreateDocument"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group class="mt-1">
        <label for="user">Người lao động <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customInput"
        >
          <v-select
            v-model="dataInput.workerId"
            :reduce="Value => Value.id"
            label="name"
            :options="dataAllWorker || []"
            :placeholder="'Lựa chọn người lao động'"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              {{ option.name }}
            </template>
            <template #selected-option-container="{ option }">
              <div class="vs__selected">
                {{ option.name }}
              </div>
            </template>
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group class="mt-1">
        <label for="user">Trình độ chuyên môn</label>
        <b-form-input v-model="dataInput.qualification" />
      </b-form-group>

      <b-form-group class="mt-1">
        <label for="user">Số năm làm công tác ATVSLĐ</label>
        <b-form-input
          v-model="dataInput.yearCount"
          type="number"
        />
      </b-form-group>

    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormGroup,
  BModal,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    vSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      customInput: {
        required: 'Người lao động là bắt buộc',
      },
      dataInput: {
        workerId: '',
        qualification: '',
        yearCount: 0,
      },
    }
  },
  computed: {
    ...mapGetters('workerPermit', ['dataAllWorker']),
  },
  mounted() {
    this.fetchDataComboboxAllWorker()
  },
  methods: {
    ...mapActions('workerPermit', ['fetchDataComboboxAllWorker']),

    // Tạo cán bộ quản lý
    handleCreateDocument(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateDocument', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        workerId: '',
        qualification: '',
        yearCount: 0,
      }
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .btn-button-file {
    width: 30%;
    height: 38px;
  }
      .input-file {
      display: none;
    }
}
</style>
