export default {
  INFORMATIN_TRAINING_BUSINESS: 'LicenseTraining/license-training-by-bussiness',
  DELETE_LICENSE_TRAINING: 'LicenseTraining/delete-license-trainings',
  ADD_LICENSE_TRAINING: 'LicenseTraining/licenseTrainings',
  EXTEND_LICENSE: 'LicenseTraining/require-extend-license-training',
  RENEW_LICENSE: 'LicenseTraining/require-renew-license-training',
  LIST_TRAINING_FIELD: 'LicenseTraining/list-license-training-field',
  CREATE_TRAINING_FILED: 'LicenseTraining/add-license-training-field',
  DELETE_TRAINING_FIELD: 'LicenseTraining/delete-license-training-field',
  LIST_FILE_LICENSE: 'LicenseTraining/list-license-training-file',
  CREATE_FILE_LICENSE: 'LicenseTraining/add-license-training-file',
  DELETE_FILE_LICENSE: 'LicenseTraining/delete-license-training-file',
  LIST_TRAINING_EQUIPMENT: 'LicenseTraining/list-license-training-equipment',
  DELETE_TRAINING_EQUIPMENT: 'LicenseTraining/delete-license-training-equipment',
  CREATE_TRAINING_EQUIPMENT: 'LicenseTraining/add-license-training-equipment',
  LIST_TRAINING_OFFICAL: 'LicenseTraining/list-license-training-offical',
  CREATE_TRAINING_OFFICAL: 'LicenseTraining/add-license-training-offical',
  DELETE_TRAINING_OFFICAL: 'LicenseTraining/delete-license-training-offical',
  DETAIL_TRAINING_OFFICAL_ID: 'LicenseTraining/licenseTrainings/',
  EDIT_GENERAL_INFORMATION: 'LicenseTraining/edit-info-license-training',
  LIST_FILE_REQUIRES: 'LicenseTraining/list-file-requires',
  UPDATE_FILE_REQUIRES: 'LicenseTraining/update-file-require',
  DOWNLOAD_FILE_REQUIRES: 'LicenseTraining/download-file-require',
  DELETE_FILE_REQUIRES: 'LicenseTraining/delete-file-require',
}
