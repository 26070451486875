<template>
  <div>
    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataTable || []"
      :sort-options="{
        enabled: false,
      }"
      :select-options="{
        enabled: false,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Chức năng -->
        <span v-if="props.column.field == 'buttonUpload'">
          <span v-if="props.row.fileId === '00000000-0000-0000-0000-000000000000'">
            <div class="d-flex">
              <input
                id="file"
                ref="upload-file"
                type="file"
                class="d-none"
                @change="importFile"
              />
              <b-button
                variant="outline-primary"
                class="text-nowrap ml-1"
                @click="uploadFile(props.row.id)"
              >
                <div
                  class="d-flex"
                >
                  <feather-icon
                    icon="UploadIcon"
                    for="file"
                    class="input_label text-primary"
                  />
                  <label
                    class="text-primary ml-50"
                  >Tải lên</label>
                </div>
              </b-button>
            </div>
          </span>
          <span
            v-else
            class="d-flex align-items-center hover-element"
          >
            <span
              v-b-tooltip.hover.top="'Tải xuống'"
              class="colors"
              @click="downloadFile(props.row)"
            >{{ props.row.fileName }}</span>
          </span>
        </span>

        <span v-else-if="props.column.field === 'actionFunction' && props.row.fileName !== null">
          <b-dropdown
            variant="link"
            boundary="viewport"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body"
              />
            </template>

            <b-dropdown-item @click="uploadFile(props.row.id)">
              <feather-icon
                icon="UploadIcon"
                for="file"
                class="mr-50"
              />
              <span>Tải lên</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteItem(props.row.fileId)">
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span>Xóa</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>
    </vue-good-table>

    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import {
  BButton,
  VBTooltip,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ConfirmModal,
    BButton,
    VBTooltip,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    licenseTrainingId: {
      type: String,
      default: '',
    },
    typeFile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Stt',
          field: 'index',
        },
        {
          label: 'TÊN HỒ SƠ',
          field: 'name',
          // width: '1000px',
        },
        {
          label: 'TỆP TIN ĐÍNH KÈM',
          field: 'buttonUpload',
        },
        {
          label: 'Chức năng',
          field: 'actionFunction',
          width: '150px',
        },
      ],
      urlQuery: {
        licenseTrainingId: this.licenseTrainingId,
        type: '',
      },
      dataTable: [],
      fileId: null,
      modelFormData: {},
      deleteIds: null,
      confirmModalId: 'confirm-modal',
      modalContent: '',
    }
  },
  created() {
    this.fetchListFileSend(this.urlQuery)
  },
  methods: {
    // Lấy danh sách hồ sơ cần nộp
    async fetchListFileSend(urlQuery) {
      if (this.typeFile === 'PendingLicensing') {
        urlQuery.type = 'CM'
      } else if (['PendingRenew', 'PendingExtend'].includes(this.typeFile)) {
        urlQuery.type = 'GH'
      }
      this.$showLoading()
      const data = await axiosApiInstance.get(ConstantsApi.LIST_FILE_REQUIRES, { params: urlQuery })
      this.dataTable = data?.data
      this.$hideLoading()
    },

    uploadFile(id) {
      this.fileId = id
      this.$refs['upload-file'].click()
    },

    // up file lên server
    async importFile(e) {
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.modelFormData = {
        files: file,
        isSecure: false,
      }

      const formData = new FormData()
      formData.append('Id', this.fileId)
      formData.append('LicenseTrainingId', this.licenseTrainingId)
      formData.append('IsSecure', this.modelFormData.isSecure)
      formData.append('formFile', this.modelFormData.files)

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.UPDATE_FILE_REQUIRES, formData).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Tải file thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListFileSend(this.urlQuery)
      }).catch(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Tải file không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Tải xuống tệp tin
    async downloadFile(fileInfo) {
      try {
        await axiosApiInstance({
          url: ConstantsApi.DOWNLOAD_FILE_REQUIRES,
          method: 'GET',
          responseType: 'blob', // important
          params: { fileId: fileInfo.fileId },
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileInfo.fileName) // or any other extension
          document.body.appendChild(link)
          link.click()
        })
      } catch {
        this.$root.$bvToast.toast('Tải xuống không thành công, vui lòng thử lại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    async deleteItem(id) {
      this.deleteIds = id
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa file cần nộp
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_FILE_REQUIRES, { id: this.deleteIds }).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListFileSend(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.colors {
  color: #227FF4 !important;
}
</style>
