<template>
  <div>
    <button-all-header
      :contentBtnAdd="'Thêm cán bộ quản lý'"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      class="custtom-header"
      @clickDelete="deleteItems"
      @clickAdd="showModalAddDocument"
      @search="search($event)"
    />
    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataListOffical || []"
      :sort-options="{
        enabled: false,
      }"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Chức năng -->
        <span v-if="props.column.field == 'action'">
          <span>
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="ml-2"
              @click="deleteItem(props.row.id)"
            />
          </span>
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />
    <!-- modal xóa-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- Thêm file -->
    <modal-add-document
      :id="modalAddDocument"
      :title="title"
      @handleCreateDocument="handleCreateDocument"
    />
  </div>
</template>
<script>
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalAddDocument from './modal-create/ModalAddDocument.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    ConfirmModal,
    ModalAddDocument,
  },
  props: {
    licenseTrainingId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showBtnMultiDelete: false,
      totalRecord: 0,
      currentPage: 0,
      columns: [
        {
          label: 'TÊN NGƯỜI LAO ĐỘNG',
          field: 'name',
        },
        {
          label: 'NĂM SINH',
          field: 'year',
        },
        {
          label: 'TRÌNH ĐỘ CHUYÊN MÔN',
          field: 'qualification',
        },
        {
          label: 'SỐ NĂM LÀM CÔNG TÁC ATVSLĐ',
          field: 'yearCount',
        },
        {
          label: 'Chức năng',
          field: 'action',
          width: '175px',
        },
      ],
      confirmModalId: 'comfirm-modal-delete',
      modalContent: '',
      arrayExcel: null,
      modalAddDocument: 'comfirm-add-document',
      dataListOffical: [],
      deleteIds: [],
      urlQuery: {
        licenseTrainingId: this.licenseTrainingId,
        key: '',
        pageSize: 10,
        pageNumber: 1,
        licenseTrainingOfficalType: 'ManageOffical',
      },
      title: 'Thêm cán bộ quản lý',
    }
  },
  created() {
    this.fetchListOffical(this.urlQuery)
  },
  methods: {

    // Lấy danh sách nhóm người dùng trong 1 người dùng
    async fetchListOffical(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_TRAINING_OFFICAL, { params: urlQuery })
      this.dataListOffical = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListOffical(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchListOffical(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchListOffical(this.urlQuery)
      }
    },

    // Thêm file vào giấy phép lao động
    async handleCreateDocument(val) {
      const modal = {
        ...val,
        yearCount: parseInt(val.yearCount, 10),
        licenseTrainingId: this.licenseTrainingId,
        licenseTrainingOfficalType: 'ManageOffical',
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.CREATE_TRAINING_OFFICAL, modal)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListOffical(this.urlQuery)
        this.$bvModal.hide(this.modalAddDocument)
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalAddDocument)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa tài liệu phục vụ kiểm định
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        licenseTrainingId: this.licenseTrainingId,
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.DELETE_TRAINING_OFFICAL, payload)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListOffical(this.urlQuery)
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // Mở modal thêm file
    showModalAddDocument() {
      this.$bvModal.show(this.modalAddDocument)
    },
  },
}
</script>

<style lang="scss">

</style>
