<template>
  <div id="testingIds">
    <b-row>
      <b-col
        xl="4"
        lg="4"
        md="4"
        sm="12"
        xs="12"
      >
        <b-button
          :disabled="['Licensing', 'Renew', 'Extend', 'PendingLicensing', 'PendingExtend', 'PendingRenew', 'RejectLicense', 'Revoke'].includes(dataInfomationTraining.statusLicenseTraining)"
          class="button-testing buttom-add"
          variant="outline-primary"
          @click="showModalTypeTraining"
        >
          Đề nghị cấp giấy chứng nhận huấn luyện ATVSLĐ
        </b-button>
        <b-button
          :disabled="!['Licensing', 'Renew', 'Extend', 'Revoke'].includes(dataInfomationTraining.statusLicenseTraining)"
          class="button-testing buttom-add"
          variant="outline-primary"
          @click="handleRenewLicense"
        >
          Đề nghị cấp lại giấy chứng nhận huấn luyện ATVSLĐ
        </b-button>
        <b-button
          :disabled="!['Licensing', 'Renew', 'Extend'].includes(dataInfomationTraining.statusLicenseTraining)"
          class="button-testing buttom-add"
          variant="outline-primary"
          @click="handleExtendLicense"
        >
          Đề nghị cấp giấy gia hạn chứng nhận huấn luyện ATVSLĐ
        </b-button>
        <div>
          <b-row>
            <b-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              xs="6"
            >
              <b-button
                :disabled="['NonLiscening'].includes(dataInfomationTraining.statusLicenseTraining)"
                variant="danger"
                class="buttom-add button-delete"
                @click="handleDeleteLicense"
              >
                Xóa
              </b-button>
            </b-col>
            <b-col
              xl="6"
              lg="6"
              md="6"
              sm="6"
              xs="6"
            >
              <b-dropdown
                id="dropdown-1"
                :disabled="['NonLiscening', 'RejectLicense', 'Revoke'].includes(dataInfomationTraining.statusLicenseTraining)"
                text="Tải đơn"
                variant="primary"
                class="buttom-add button-action"
                size="lg"
              >
                <b-dropdown-item
                  v-if="![
                    'PendingRenew',
                    'PendingExtend',
                    'Licensing',
                    'Renew',
                    'Extend'].includes(dataInfomationTraining.statusLicenseTraining)"
                  @click="openModalSeePdf(dataInfomationTraining.id)"
                >
                  <span class="align-middle">Đơn đề nghị cấp giấy chứng nhận đủ điều kiện hoạt động huấn luyện</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="checkStatus"
                  @click="openModalSeePdfWritten(dataInfomationTraining.id)"
                >
                  <span class="align-middle">Bản thuyết minh về quy mô huấn luyện và các điều kiện, giải pháp thực hiện</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="['Licensing', 'Renew', 'Extend'].includes(dataInfomationTraining.statusLicenseTraining)"
                  @click="openModalSeePdfAccreditation(dataInfomationTraining.id)"
                >
                  <span class="align-middle">Giấy chứng nhận đủ điều kiện hoạt động huấn luyện</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col
        xl="6"
        lg="6"
        md="6"
        sm="12"
        xs="12"
      >
        <b-row>
          <span class="title-text">Thông tin giấy phép</span>
        </b-row>
        <b-row class="mt-2 justify-content-between">
          <b-col>
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Trạng thái</span>
          </b-col>
          <b-badge
            pill
            :variant="colorStatus(dataInfomationTraining.statusLicenseTraining)"
            class="border-status"
          >
            <span> {{ dataInfomationTraining.statusLicenseTrainingString }}</span>
          </b-badge>
        </b-row>
        <b-row class="mt-2 justify-content-between">
          <b-col>
            <feather-icon
              icon="UserIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Số giấy phép</span>
          </b-col>
          <span class="align-middle">{{ dataInfomationTraining.numberLicense }}</span>
        </b-row>
        <b-row class="mt-2 justify-content-between">
          <b-col>
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Ngày cấp phếp</span>
          </b-col>
          <span class="align-middle">{{ dataInfomationTraining.dateLicense | formatDateToDDMM }}</span>
        </b-row>
        <b-row class="mt-2 justify-content-between">
          <b-col>
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Ngày hết hạn</span>
          </b-col>
          <span class="align-middle">{{ dataInfomationTraining.dateExpired | formatDateToDDMM }}</span>
        </b-row>
        <b-row
          v-if="['PendingLicensing'].includes(dataInfomationTraining.statusLicenseTraining)"
          class="mt-2 justify-content-between"
        >
          <b-col>
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Ngày hẹn trả</span>
          </b-col>
          <span class="align-middle">{{ dataInfomationTraining.dateLicenseProcess | formatDateToDDMM }}</span>
        </b-row>
        <b-row
          v-if="['PendingRenew'].includes(dataInfomationTraining.statusLicenseTraining)"
          class="mt-2 justify-content-between"
        >
          <b-col>
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Ngày hẹn trả</span>
          </b-col>
          <span class="align-middle">{{ dataInfomationTraining.dateRenewProcess | formatDateToDDMM }}</span>
        </b-row>
        <b-row
          v-if="['PendingExtend'].includes(dataInfomationTraining.statusLicenseTraining)"
          class="mt-2 justify-content-between"
        >
          <b-col>
            <feather-icon
              icon="CalendarIcon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Ngày hẹn trả</span>
          </b-col>
          <span class="align-middle">{{ dataInfomationTraining.dateExtendProcess | formatDateToDDMM }}</span>
        </b-row>
        <b-row
          class="mt-2 justify-content-between"
        >
          <b-col>
            <feather-icon
              icon="BarChart2Icon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">Hạng</span>
          </b-col>
          <span
            v-if="dataInfomationTraining.licenseTrainingType"
            class="align-middle"
          >{{ `${dataInfomationTraining.licenseTrainingType === 'TypeC' ?'Hạng C': 'Hạng B'}` }}</span>
        </b-row>
      </b-col>
    </b-row>

    <!-- modal xem file pdf -->
    <modal-see-pdf
      :id="modalSeePdf"
      :pdfsrc="pdfsrc"
      @downloadFile="downloadFilePdf"
    />

    <ModalCreateTypeTraining
      id="modal-create-type-training"
      title="Điều kiện huấn luyện hạn"
      :dataCombobox="typeTraining"
      @accept="handleLicenseTraining"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BRow, BCol, BButton, BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import ModalCreateTypeTraining from './modal-create/ModalCreateTypeTranining.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import ModalSeePdf from './modal-create/ModalSeePdf.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BBadge,
    ModalSeePdf,
    ModalCreateTypeTraining,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    dataInfomationTraining: {
      type: Object,
      default: _ => {},
    },
    fetchDataTraining: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalSeePdf: 'comfirm-pdf-business',
      pdfsrc: null,
      modeType: null,
      typeTraining: [
        {
          vi: 'Hạng B',
          en: 'TypeB',
        },
        {
          vi: 'Hạng C',
          en: 'TypeC',
        },
      ],
    }
  },
  computed: {
    colorStatus() {
      const statusColor = {
        NonLiscening: 'secondary',
        PendingLicensing: 'warning',
        Licensing: 'primary',
        RejectLicense: 'danger',
        PendingRenew: 'warning',
        PendingExtend: 'warning',
        Revoke: 'danger',
        Extend: 'success',
        Renew: 'primary',
      }
      return status => statusColor[status]
    },

    checkStatus() {
      return ['PendingLicensing', 'PendingExtend', 'PendingRenew'].includes(this.dataInfomationTraining.statusLicenseTraining)
    },
  },
  methods: {

    showModalTypeTraining() {
      this.$bvModal.show('modal-create-type-training')
    },

    // Thêm đề nghị cấp phép kiểm định
    async handleLicenseTraining(val) {
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.ADD_LICENSE_TRAINING, val)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm đề nghị cấp chứng nhận huấn luyện thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide('modal-create-type-training')
        this.fetchDataTraining()
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm đề nghị cấp chứng nhận huấn luyện không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // Thêm đề nghị gia hạn cấp phép kiểm định
    async handleExtendLicense() {
      const extendId = {
        licenseTrainingId: this.dataInfomationTraining.id,
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.EXTEND_LICENSE, extendId)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Đề nghị cấp giấy gia hạn chứng nhận huấn luyện ATVSLĐ thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataTraining()
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Đề nghị cấp giấy gia hạn chứng nhận huấn luyện ATVSLĐ không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // Thêm đề nghị cấp phép kiểm định
    async handleRenewLicense() {
      const renewId = {
        licenseTrainingId: this.dataInfomationTraining.id,
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.RENEW_LICENSE, renewId)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Đề nghị cấp giấy cấp lại chứng nhận huấn luyện ATVSLĐ thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataTraining()
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Đề nghị cấp giấy cấp lại chứng nhận huấn luyện ATVSLĐ không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // xóa cấp phép kiểm định
    handleDeleteLicense() {
      this.$emit('handleDeleteLicense')
    },

    // Mở modal Tạo đơn đề nghị cấp giấy chứng nhận đủ điều kiện hoạt động huấn luyện
    openModalSeePdf(id) {
      this.modeType = 'require'
      this.licenseTrainingId = id
      this.urlApi = '/LicenseTraining/file-require-license'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseTrainingId: this.licenseTrainingId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo bản thuyết minh về quy mô huấn luyện và các điều kiện, giải pháp thực hiện
    openModalSeePdfWritten(id) {
      this.modeType = 'written'
      this.licenseTrainingId = id
      this.urlApi = '/LicenseTraining/file-require-written-explanation'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseTrainingId: this.licenseTrainingId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo giấy chứng nhận đủ điều kiện hoạt động huấn luyện
    openModalSeePdfAccreditation(id) {
      this.modeType = 'document'
      this.licenseTrainingId = id
      this.urlApi = '/LicenseTraining/file-license-training'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseTrainingId: this.licenseTrainingId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file pdf
    downloadFilePdf() {
      switch (this.modeType) {
        case 'require':
          this.nameFilePdf = 'DonDeNghiCapGiayChungNhanHuanLuyen.pdf'
          break
        case 'written':
          this.nameFilePdf = 'BanThuyetMinhQuyMoHuanLuyenVaCacDieuKienGiaiPhapThucHien.pdf'
          break
        case 'document':
          this.nameFilePdf = 'GiayChungNhanDuDieuKienHuanLuyen.pdf'
          break
        default:
          break
      }
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseTrainingId: this.licenseTrainingId },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.nameFilePdf) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>

<style lang="scss">
#testingIds {
  .button-testing {
    min-width: 100%;
    margin-bottom: 1rem;
  }
  .buttom-add {
    // height: 42px;
    button {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
  .button-action {
    min-width: 100%;
  }
  .button-delete {
    height: 40px;
    min-width: 100%;
  }
  .title-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
  .border-status {
    border-radius: 4px;
  }
}
</style>
