<template>
  <div>
    <button-all-header
      :contentBtnAdd="'Thêm dụng cụ, thiết bị kiểm định'"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataListEquipment || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Chức năng -->
        <span v-if="props.column.field === 'actionFunction'">
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- Tạo Dụng cụ, thiết bị kiểm định -->
    <modal-create-equipment
      :id="modalCreateEquipment"
      @handleCreateEquipment="handleCreateEquipment"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreateEquipment from './modal-create/ModalCreateEquipment.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    BDropdown,
    BDropdownItem,
    ButtonAllHeader,
    ConfirmModal,
    ModalCreateEquipment,
  },
  filters: {
    formatDateToDDMM,
  },
  props: {
    licenseTrainingId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'TÊN THIẾT BỊ',
          field: 'name',
          sortable: false,
        },
        {
          label: 'SỐ LƯỢNG',
          field: 'quantity',
          sortable: false,
        },
        {
          label: 'NĂM SẢN XUẤT',
          field: 'year',
          sortable: false,
        },
        {
          label: 'Chức năng',
          field: 'actionFunction',
          width: '175px',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        licenseTrainingId: this.licenseTrainingId,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal-equipment',
      modalCreateEquipment: 'confirm-eqiupment',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      dataListEquipment: [],
      totalRecord: 0,
    }
  },
  created() {
    this.fetchListEquipment(this.urlQuery)
  },
  methods: {

    // Lấy danh sách nhóm người dùng trong 1 người dùng
    async fetchListEquipment(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_TRAINING_EQUIPMENT, { params: urlQuery })
      this.dataListEquipment = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListEquipment(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchListEquipment(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchListEquipment(this.urlQuery)
      }
    },

    // mở modal tạo mới đối tượng kiểm định
    showModalCreate() {
      this.$bvModal.show(this.modalCreateEquipment)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Mở modal nhóm người dùng
    handleModalGroupUser() {
      this.$bvModal.show('modalGroup')
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa dụng cụ, thiết bị phục vụ kiểm định
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        licenseTrainingId: this.licenseTrainingId,
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.DELETE_TRAINING_EQUIPMENT, payload)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListEquipment(this.urlQuery)
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    // Thêm dụng cụ, thiết bị phục vụ kiểm định
    async handleCreateEquipment(val) {
      const payload = {
        ...val,
        quantity: parseInt(val.quantity, 10),
        licenseTrainingId: this.licenseTrainingId,
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.CREATE_TRAINING_EQUIPMENT, payload)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListEquipment(this.urlQuery)
        this.$bvModal.hide(this.modalCreateEquipment)
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thất bại', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalCreateEquipment)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.text-name {
  color: #227ff4;
}
</style>
