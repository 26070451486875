<template>
  <b-form class="content-form">
    <b-row>
      <b-col
        sm="6"
        md="6"
        lg="6"
        xl="6"
      >
        <b-form-group>
          <label for="jobCenterDecrease">Công trình và tổng diện tích sử dụng của từng công trình của trụ sở chính</label>
          <b-form-input v-model="dataInput.squareHeadquarter" />
        </b-form-group>
      </b-col>

      <b-col
        sm="6"
        md="6"
        lg="6"
        xl="6"
      >
        <b-form-group>
          <label for="jobCenterDecrease">Công trình và tổng diện tích sử dụng của từng công trình của chi nhánh/cơ sở huấn luyện khác</label>
          <b-form-input v-model="dataInput.squareBranch" />
        </b-form-group>
      </b-col>

      <b-col
        sm="6"
        md="6"
        lg="6"
        xl="6"
      >
        <b-form-group>
          <label for="jobCenterDecrease">Phòng học được sử dụng chung</label>
          <b-form-input v-model="dataInput.squareRoom" />
        </b-form-group>
      </b-col>

      <b-col
        sm="6"
        md="6"
        lg="6"
        xl="6"
      >
        <b-form-group>
          <label for="jobCenterDecrease">Phòng thí nghiệm/thực nghiệm, xưởng thực hành</label>
          <b-form-input v-model="dataInput.squareLab" />
        </b-form-group>
      </b-col>

      <b-col
        sm="6"
        md="6"
        lg="6"
        xl="6"
      >
        <b-form-group>
          <label for="jobCenterDecrease">Công trình phụ trợ</label>
          <b-form-input v-model="dataInput.squareAuxiliary" />
        </b-form-group>
      </b-col>

    </b-row>

    <div class="d-flex mt-1">
      <b-button
        variant="primary"
        class="mr-2"
        @click="save"
      >
        Lưu lại
      </b-button>
    </div>
  </b-form>
</template>

<script>

import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
  },
  props: {
    licenseTrainingId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataDetailId: {},
      dataInput: {
        squareHeadquarter: '',
        squareBranch: '',
        squareRoom: '',
        squareLab: '',
        squareAuxiliary: '',
      },
    }
  },

  watch: {
    dataDetailId(val) {
      if (val) {
        this.dataInput = {
          squareHeadquarter: this.dataDetailId.squareHeadquarter,
          squareBranch: this.dataDetailId.squareBranch,
          squareRoom: this.dataDetailId.squareRoom,
          squareLab: this.dataDetailId.squareLab,
          squareAuxiliary: this.dataDetailId.squareAuxiliary,
        }
      }
    },
  },

  created() {
    this.fetchDataDetailId()
  },
  methods: {
    async fetchDataDetailId() {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_TRAINING_OFFICAL_ID}${this.licenseTrainingId}`)
      this.dataDetailId = data
    },

    async save() {
      const payload = {
        ...this.dataInput,
        id: this.licenseTrainingId,
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.put(ConstantsApi.EDIT_GENERAL_INFORMATION, payload)
      if (res.status === 200) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Chỉnh sửa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataDetailId()
      } else {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Chỉnh sửa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
  },
}
</script>
